<template>
    <div>
  
      <div class="commonInputTopBox">
  
      <pageHeader :pagename="'月保车辆列表'" :total="total"/>
  
      <el-form :inline="true" class="demo-form-inline">
        <el-form-item label="车牌号搜索：">
          <el-input placeholder="车牌号" v-model="query.license_plate"></el-input>
        </el-form-item>
        <el-form-item label="手机号搜索：">
          <el-input placeholder="手机号" v-model="query.phone"></el-input>
        </el-form-item>
        <el-form-item style="float: right;">
          <el-button type="primary">查询</el-button>
        </el-form-item>
      </el-form>
  
      </div>
       <div class="commonControl-body">
          <common-table
            :tableData="
              tableData
            "
            :tableButton="tableButton"
            :tableLabel="tableLabel"
            @edit="editFirstLevel"
          ></common-table>
          <div class="layoutBorder">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-sizes="[5, 10, 20, 40]"
              :page-size="pagesize"
              layout="total, sizes, prev, pager, next, jumper"
            >
            </el-pagination>
          </div>
        </div>
    
  
    </div>
  </template>
  
  
  
  <script>
  
  import { monthly_pass_query } from "@/api/manage/business/monthlypass/monthlypass.js";
  import CommonTable from "frontelementpackage/src/CommonTableButtonFixed.vue";
  
  
  
  export default {
    name: "start",
    data() {
      return {
        query: {
          // querypage: 1, //获取页码
          // pagesize: 10, //每页返回数量
          garage_id: '', 
          user_phone: '',
          license_plate: '' 
        },
        //分页数据
        total: 0,
        currentPage: 1, //初始页
        pagesize: 10, //    每页的数据
        tableData: [],
        dialogFormVisibleEdit: false,
        formEdit: { group_info: "" },
        dialogFormVisibleAdd: false,
        formAdd: {},
        tableLabel: [
          {
            prop: "carnumber",
            label: "月保编号",
            // width: 150,
            minWidth:"10%"
          },
          {
            prop: "license_plate",
            label: "车牌号",
            // width: 150,
            minWidth:"10%"
          },
          {
            prop: "info_address",
            label: "车库地址",
            // width: 150,
            minWidth:"10%"
          },
          {
            prop: "info_phone",
            label: "联系电话",
            // width: 150,
            minWidth:"10%"
          },
          {
            prop: "company_status",
            label: "车库状态",
            width: 150,
            minWidth:"10%"
          },
          {
            
            prop: "space",
            label: "车位数",
            width: 150,
            minWidth:"10%"
          },
          {
            
            prop: "info_floor",
            label: "楼层",
            width: 150,
            minWidth:"10%"
          }
        ],
        tableButton: {
          width: 300,
          data:[
          {
            name: "编辑",
            type: "primary",
            size: "small",
          },
          {
            name: "删除",
            type: "danger",
            size: "small",
          },]
         
        }
      };
    },
    components: {
      CommonTable
    },
    created() {
        this.getFirstLevel();
    },
    methods: {
      editFirstLevel(row,methods) {
        if(methods=="编辑"){
          this.$router.push({name:"garage_Manage_Info",query:row})
        }
  
      
        if(methods=="删除"){
          this.delUser(row)
        }
      },
      toInfo(info){
        console.log(info)
      },
      editUser(row) {
        console.log(row,"sss")
        this.formEdit = {
          garage_id: row.garage_id,
          info_name: row.info_name,
          info_phone: row.info_phone,
          info_address:row.info_address,
          info_introduce:row.info_introduce
        };
       
        this.dialogFormVisibleEdit = true;
      },
      delUser(row) {
        let ids = row.id;
        console.log({ group_id: ids });
        // this.$confirm("是否确定删除", "确认信息", {
        //   distinguishCancelAndClose: true,
        //   confirmButtonText: "删除",
        //   cancelButtonText: "放弃删除",
        // })
        //   .then(() => {
        //     user_group_manage_delete({ group_id: ids }).then((res) => {
        //       if (res.code == 200) {
        //         this.$message.success("成功删除");
        //         window.location.reload();
        //       } else {
        //         this.$message.info(res.msg);
        //       }
        //     });
        //   })
        //   .catch((action) => {
        //     this.$message({
        //       type: "info",
        //       message: action === "cancel" ? "放弃删除" : "停留在当前",
        //     });
        //   });
      },
      handleCurrentChange(val) {
        this.currentPage = val;
        console.log(this.query)
        //  console.log("当前页面显示xxx条", this.currentPage,"当前界面是xxx页",this.currentPage);
        // monthly_pass_query(this.query).then((res) => {
        //   console.log(this.tableData, "new");
        //   this.tableData = res.data;
        // });
      },
      handleSizeChange(val) {
        this.pagesize = val; //获取page-sizes里的每页显示几条数据的值，赋给我们自定义的每页显示数量的变量pageNum
        // console.log("当前页面显示xxx条", val,"当前界面是xxx页",this.currentPage);
        this.query = {
          querypage: this.currentPage, 
          pagesize: this.pagesize, 
          keyword: "", 
          company_status:0
        };
        console.log(this.query)
        // monthly_pass_query(this.query).then((res) => {
        //   console.log(this.tableData, "new");
        //   this.tableData = res.data;
        // });
      },
  
      createFirstLevel() {
        // user_group_manage_create(this.formAdd).then((res) => {
        //   if (res.code == 200) {
        //     this.$message.success("成功添加");
        //     window.location.reload();
        //   } else {
        //     this.$message.info(res.msg);
        //   }
        // });
        this.dialogFormVisibleEdit = false;
      },
      addFormButton() {
        this.dialogFormVisibleAdd = true;
      },
      
      getFirstLevel() {
        monthly_pass_query(this.query).then((res) => {
          this.tableData = res.data.map(e=>{
            let {info_address,info_phone,info_introduce,company_status,tcpclient_empower_status,space,info_floor} = e.garage
            return{
              carnumber:e.carnumber,
              license_plate:e.car.license_plate,
              info_address,
              info_phone,
              info_introduce,
              company_status,
              tcpclient_empower_status,
              space,
              info_floor,
            }
          })
          this.total = res.data.total;
          console.log(this.tableData);
        });

      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  </style>